import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  //Form Group
  public contactForm!: FormGroup;

  constructor(
    public apiService: apiservice,
    public constant: ConstantsService) {
    this.setForm();
  }

  setForm() {
    this.contactForm = this.apiService.formBuilder.group({
      Name: new FormControl('', Validators.compose([Validators.minLength(2), Validators.maxLength(25), Validators.required, Validators.pattern("^[A-Za-z]{2,}([ ][A-Za-z]{1,})?$")])),
      Email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$")]),
      Message: new FormControl('', [Validators.required]),
    })
  }

  addcontact() {

    if (this.contactForm.invalid) {
      this.apiService.toastr.error('(*) mark fields are required');
      return;
    }

    let data = {
      'name': this.contactForm.value.Name,
      'email': this.contactForm.value.Email,
      'message': this.contactForm.value.Message,
    }
    this.apiService.postRequest(this.constant.CONTACT, data).then((res: any) => {
      this.apiService.toastr.success(res['message']);
    }).catch(err => {
      console.log(err);
    })
  }

}
