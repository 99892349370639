<section class="team1 cid-suylmxCp71" id="team1-j" style="margin-top: 30px; margin-bottom: 20px;">

    <div class="container">
        <div class="row gy-4 align-items-stretch justify-content-center">
            <div class="col-12">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>Shows</strong>
                </h3>
            </div>
        </div>

        <div *ngFor="let i of showsList" class="mb-2 mt-2">
            <div class="card-header" role="tab" id="headingOne">
                <h4> {{i.years}}</h4>
            </div>
            <br>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
                <div class="col" *ngFor="let j of i['data']">
                    <div class="card-wrap">
                        <div class="image-wrap">
                            <img [lazyLoad]="apiService?.fileUrl + j.eventPhoto" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <br> -->
        <!-- <div class="card-header" role="tab" id="headingOne">
            <h4> 2021</h4>
        </div> -->
        <!-- <br>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_6777.jpg">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_6825.JPEG">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_7235.JPG">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_7324.JPG">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_7330.JPG">
                    </div>
                </div>
            </div>

        </div>
        <br>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_7336.JPG">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_8464.JPG">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_8470.JPG">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_4048.JPG">
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card-wrap">
                    <div class="image-wrap">
                        <img src="assets/S1/IMG_2097.JPG">
                    </div>
                </div>
            </div>

        </div> -->
    </div>
</section>