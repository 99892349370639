<section class="team1 cid-suylmxCp71" id="team1-j" style="margin-top: 30px; margin-bottom: 20px;">
    <div class="container">
        <div class="row gy-4 align-items-stretch justify-content-center">
            <div class="col-12 ">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>Workshops</strong>
                </h3>
            </div>
        </div>
        <div *ngFor="let i of workShopList" class="mb-2 mt-2">
            <div class="card-header" role="tab" id="headingOne">
                <h4> {{i.years}}</h4>
            </div>
            <br>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
                <div class="col" *ngFor="let j of i['data']">
                    <div class="card-wrap">
                        <div class="image-wrap">
                            <img [lazyLoad]="apiService?.fileUrl + j.eventPhoto" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>