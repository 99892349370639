<section class="footer6 cid-suyk9dRr8f py-4" once="footers" id="footer6-5">
    <div class="container">
        <div class="row content mbr-white">
            <div class="col-12 col-md-6 col-lg-3 mbr-fonts-style">
                <h5 class="mbr-section-subtitle mbr-fonts-style">
                    <strong>Social</strong>
                </h5>
                <div class="social-list align-left">
                    <div class="soc-item">
                        <a href="https://www.facebook.com/theartbyrs" target="_blank">
                            <span class="socicon-facebook socicon mbr-iconfont mbr-iconfont-social aa"></span>
                        </a>
                    </div>
                    <div class="soc-item">
                        <a href="https://www.instagram.com/theartbyrs/" target="_blank">
                            <span class="socicon-instagram socicon mbr-iconfont mbr-iconfont-social aa"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mbr-fonts-style">
                <h5 class="mbr-section-subtitle mbr-fonts-style">
                    <strong>Contact Us</strong>
                </h5>
                <a class="aa" href="mailto:artoonistsbyrs@gmail.com">
                    <p class="mbr-text mbr-fonts-style text-white aa">
                        artoonistsbyrs@gmail.com
                    </p>
                </a>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mbr-fonts-style">
                <h5 class="mbr-section-subtitle mbr-fonts-style">
                    <strong>Links</strong>
                </h5>
                <ul class="list mbr-fonts-style mb-4 display-4">
                    <li class="mbr-text item-wrap">
                        <p class="text-whites aa" routerLink="/" routerLinkActive="text-secondary"
                            [routerLinkActiveOptions]="{exact: true}">Home</p>
                    </li>
                    <li class="mbr-text item-wrap">
                        <p class="text-whites aa" routerLink="/aboutus" routerLinkActive="text-secondary">About Us</p>
                    </li>
                    <li class="mbr-text item-wrap">
                        <p class="text-whites aa" routerLink="/artists" routerLinkActive="text-secondary">Artists</p>
                    </li>
                    <li class="mbr-text item-wrap">
                        <p class="text-whites aa" routerLink="/events" routerLinkActive="text-secondary">Events</p>
                    </li>
                    <li class="mbr-text item-wrap">
                        <p class="text-whites aa" routerLink="/all-blogs" routerLinkActive="text-secondary">Blogs</p>
                    </li>
                    <li class="mbr-text item-wrap">
                        <p class="text-whites aa" routerLink="/contactus" routerLinkActive="text-secondary">Contact Us
                        </p>
                    </li>
                    <li class="mbr-text item-wrap">
                        <p class="text-whites aa" routerLink="/the-pink-dot" routerLinkActive="text-secondary">The Pink Dot
                            Returns</p>
                    </li>
                </ul>
            </div>

            <div class="col-sm-12">
                <hr class="mt-0">
            </div>
            <div class="col-sm-12 copyright">
                <p class="mbr-text mbr-fonts-style mbr-white display-7">
                    © Copyright {{ dateTime | date:'yyyy' }} Artbyrs - All Rights Reserved
                </p>
            </div>
            <div class="col-12 mbr-fonts-style">
                <ul class="list mbr-fonts-style mb-4 display-4">
                    <li class="mbr-text item-wrap">
                        <p class="text-whites">
                            All artists' work are their own and do not constitute any advice whatsoever. Nothing
                            published by ARTBYRS constitutes an investment recommendation or expert views on
                            Art.<br><br>

                            ARTBYRS strongly recommend you perform your independent research and/or speak with the
                            artist for any purchase.<br><br>

                            Any purchase or decision shall exclusively rest with the purchaser and ARTBYRS shall not be
                            liable or held liable for any consequences thereof.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>