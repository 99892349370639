import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ConstantsService {

  ARTIST = 'artist';
  SINGLEARTIST = 'artists';
  GALLERY = 'artwork';
  SINGLEGALLERY = 'singleArtwork';
  CONTENT = 'content';
  CONTACT = 'contact';
  EVENTSSITE = 'event/siteType';
  SHOWS = 'event/Shows';
  WORKSHOPS = 'event/Workshops';
  Blog = 'blog';

}
