<div class="container py-5">
    <div class="row text-center my-5">
        <div class="col-12 col-md-6 pt">
            <h3 class="align-center display-4 fontcolor_red" data-heading="Get in Touch"><span
                    data-heading="Get in Touch">Get in Touch</span></h3>
            <p class="mb-4 pt">Please fill out the form and we'll be in touch</p>
            <form [formGroup]="contactForm" autocomplete="off">
                <div class="row justify-content-center">
                    <div class="col-8 mb-3">
                        <div class="mb-3">
                            <input type="text" class="form-control" formControlName="Name" placeholder="Name">
                            <div *ngIf="contactForm.get('Name')?.invalid && (contactForm.get('Name')?.dirty || contactForm.get('Name')?.touched)"
                                class="alert mb-0">
                                <div *ngIf="contactForm.get('Name')?.errors?.required">
                                    Name is required.
                                </div>
                                <div *ngIf="contactForm.get('Name')?.errors?.pattern">
                                    Please enter your proper name
                                </div>
                                <div
                                    *ngIf="contactForm.get('Name')?.errors?.minlength || contactForm.get('Name')?.errors?.maxlength">
                                    Please enter name of minimum 3 and maximum 50 characters
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <input type="email" class="form-control" formControlName="Email" placeholder="Email">
                            <div *ngIf="contactForm.get('Email')?.invalid && (contactForm.get('Email')?.dirty || contactForm.get('Email')?.touched)"
                                class="alert mb-0">
                                <div *ngIf="contactForm.get('Email')?.errors?.required">
                                    Email is required.
                                </div>
                                <div *ngIf="contactForm.get('Email')?.errors?.pattern">
                                    Please include an valid email address
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <textarea class="form-control" formControlName="Message" placeholder="Message"
                                rows="3"></textarea>
                            <div *ngIf="contactForm.get('Message')?.invalid && (contactForm.get('Message')?.dirty || contactForm.get('Message')?.touched)"
                                class="alert mb-0">
                                <div *ngIf="contactForm.get('Message')?.errors?.required">
                                    Message is required.
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Message Sent</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-3">
                            <button type="submit" class="btn mx-auto px-5 cursor" (click)="addcontact()"
                                [disabled]="!contactForm.valid"
                                style="background-color: black;color: #ffc107;border-radius: 0px;" data-toggle="modal"
                                data-target="#exampleModal">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 col-md-6">
            <div class="row">
                <div class="col-12 pt">
                    <h3 class="align-center display-4 fontcolor_red" data-heading="Stay updated"><span
                            data-heading="Stay updated">Stay updated</span></h3>
                    <div class="my-4 pt">
                        <span class="mx-4"><a href="mailto:artoonistsbyrs@gmail.com" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448" fill="black" width="60"
                                    height="60" data-ux="IconSocial"
                                    class="x-el x-el-svg c1-1 c1-2 c1-7y c1-1u c1-6k c1-bg c1-bh c1-bi c1-b c1-c c1-bj c1-bk c1-d c1-bl c1-bm c1-bn c1-bo c1-bp c1-bq c1-br c1-bs c1-e c1-f c1-g">
                                    <path d="m314.375 144h-180.75l90.375 77.464844zm0 0" />
                                    <path
                                        d="m224 240c-1.910156 0-3.757812-.683594-5.207031-1.929688l-98.792969-84.679687v150.609375h208v-150.609375l-98.792969 84.679687c-1.449219 1.246094-3.296875 1.929688-5.207031 1.929688zm0 0" />
                                    <path
                                        d="m224 0c-123.710938 0-224 100.289062-224 224s100.289062 224 224 224 224-100.289062 224-224c-.140625-123.652344-100.347656-223.859375-224-224zm120 312c0 4.417969-3.582031 8-8 8h-224c-4.417969 0-8-3.582031-8-8v-176c0-4.417969 3.582031-8 8-8h224c4.417969 0 8 3.582031 8 8zm0 0" />
                                </svg>
                            </a></span>
                    </div>
                    <div class="my-4">
                        <span class="mx-4"><a href="https://www.facebook.com/theartbyrs" target="_blank">
                                <svg viewBox="0 0 24 24" fill="black" width="60" height="60" data-ux="IconSocial"
                                    class="x-el x-el-svg c1-1 c1-2 c1-7y c1-1u c1-6k c1-bg c1-bh c1-bi c1-b c1-c c1-bj c1-bk c1-d c1-bl c1-bm c1-bn c1-bo c1-bp c1-bq c1-br c1-bs c1-e c1-f c1-g">
                                    <path fill-rule="evenodd"
                                        d="M22 12.061C22 6.505 17.523 2 12 2S2 6.505 2 12.061c0 5.022 3.657 9.184 8.438 9.939v-7.03h-2.54v-2.91h2.54V9.845c0-2.522 1.492-3.915 3.777-3.915 1.094 0 2.238.197 2.238.197v2.476h-1.26c-1.243 0-1.63.775-1.63 1.57v1.888h2.773l-.443 2.908h-2.33V22c4.78-.755 8.437-4.917 8.437-9.939z">
                                    </path>
                                </svg>
                            </a></span>
                    </div>
                    <div class="my-4">
                        <span class="mx-4"><a href="https://www.instagram.com/theartbyrs/" target="_blank">
                                <svg viewBox="0 0 24 24" fill="black" width="60" height="60" data-ux="IconSocial"
                                    class="x-el x-el-svg c1-1 c1-2 c1-7y c1-1u c1-6k c1-bg c1-bh c1-bi c1-b c1-c c1-bj c1-bk c1-d c1-bl c1-bm c1-bn c1-bo c1-bp c1-bq c1-br c1-bs c1-e c1-f c1-g">
                                    <path
                                        d="M16.604 8.516c.13.35.198.719.203 1.091.033.622.033.811.033 2.386 0 1.574-.004 1.763-.033 2.385a3.273 3.273 0 0 1-.203 1.091 1.956 1.956 0 0 1-1.12 1.12c-.35.13-.719.198-1.091.204-.622.032-.811.032-2.386.032-1.574 0-1.763-.003-2.385-.032a3.273 3.273 0 0 1-1.091-.204 1.956 1.956 0 0 1-1.12-1.12 3.273 3.273 0 0 1-.204-1.09c-.032-.623-.032-.812-.032-2.386 0-1.575.003-1.764.032-2.386.006-.372.074-.741.204-1.09a1.956 1.956 0 0 1 1.12-1.12c.35-.13.718-.199 1.09-.204.623-.033.812-.033 2.386-.033 1.575 0 1.764.004 2.386.033.372.005.741.074 1.09.203.515.2.922.606 1.12 1.12zM12 15.033a3.033 3.033 0 1 0 0-6.066 3.033 3.033 0 0 0 0 6.066zm3.153-5.477a.71.71 0 1 0 0-1.418.71.71 0 0 0 0 1.418zM12 13.967a1.967 1.967 0 1 1 0-3.934 1.967 1.967 0 0 1 0 3.934zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2zm5.87 12.433c-.01.49-.102.974-.274 1.432a3.018 3.018 0 0 1-1.727 1.728 4.335 4.335 0 0 1-1.433.272c-.629.03-.829.037-2.432.037-1.604 0-1.819 0-2.433-.037a4.335 4.335 0 0 1-1.433-.272 3.018 3.018 0 0 1-1.727-1.728 4.335 4.335 0 0 1-.273-1.432c-.029-.63-.036-.83-.036-2.433 0-1.604 0-1.818.036-2.433.01-.49.102-.974.273-1.432a3.018 3.018 0 0 1 1.727-1.728 4.335 4.335 0 0 1 1.433-.272c.629-.03.829-.037 2.433-.037 1.603 0 1.818 0 2.432.037.49.009.974.101 1.433.272.794.307 1.42.934 1.727 1.728.172.458.264.943.273 1.432.03.63.036.83.036 2.433 0 1.604-.007 1.804-.036 2.433z">
                                    </path>
                                </svg>
                            </a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>