<section class="team1 cid-suylmxCp71" id="team1-j">
    <!-- <div class="container" style="margin-top: 20px;">
        <div class="row p-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 p-0">
                        <img src="assets/banner/banner1.PNG" class="img-fluid w-100 plr" alt="..."
                            style="padding-bottom: 60px;">
                        <h3 class="align-center display-4 fontcolor_red" data-heading="Artbyrs">Artbyrs</h3>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="col-12 container" style="width: 5000px; padding-top: 100px;">
        <br /><br /><br />
        <div class="d-flex mx-3">
        </div>
        <div class="gal container"><!-- infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000"
            (scrolled)="onScroll()" -->
            <div class="hovereffect item w-" routerLink="/artwork/{{g.id}}" *ngFor="let g of totalArtworksList">
                <img [lazyLoad]="apiService?.fileUrl + g.artworkPhoto" onerror="this.src = 'assets/images/loader.gif'"
                    style="width:100%;">
                <div class="centered1" *ngIf="g.sold == true">
                    <img src="assets/logo/Sold-Icon.png">
                </div>
                <div class="overlay">
                    <h5><b>{{g.artworkTitle}}</b></h5>
                    <h5 *ngIf="g.artist">{{g.artist.displayName}}</h5>
                    <h6 class="pb-3"><small>{{g.artworkSize}}</small></h6>
                </div>
            </div>
        </div>
        <div class="d-flex my-5 mx-3">
        </div>
    </div>
</section>