import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.scss']
})
export class ArtworkComponent implements OnInit {

  public selectedArtwork: any;
  public artlist: Array<any> = [];
  public i: number = 0;

  constructor(
    public apiService: apiservice,
    public constant: ConstantsService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getgallery(parseInt(params.id));
    });
    this.getSelectedArtGallery();
  }

  async getSelectedArtGallery() {
    const artworkList = await this.apiService.storage.getItem('artworkList');
    if (artworkList && artworkList.length > 0) {
      this.artlist = artworkList;
      this.i = 0;  // Initialize the index when the list is loaded
    }
  }

  getgallery(id: number) {
    this.apiService.getRequest(this.constant.SINGLEGALLERY + '?id=' + id, '').then((res: any) => {
      this.selectedArtwork = res['data'][0];
    }).catch(err => {
      console.log(err);
    });
  }

  nextItem() {
    if (this.artlist && this.artlist.length > 0) {
      // Increment the index and wrap around if necessary
      this.i = (this.i + 1) % this.artlist.length;

      // Get the next element from the list
      const nextArtwork = this.artlist[this.i];
      this.apiService.router.navigate(['/artwork/', nextArtwork.id]);
    }
  }

  prevItem() {
    if (this.artlist && this.artlist.length > 0) {
      // Decrement the index and wrap around if necessary
      if (this.i === 0) {
        this.i = this.artlist.length - 1;  // Wrap to the last item if index is at 0
      } else {
        this.i--;
      }

      // Get the previous element from the list
      const prevArtwork = this.artlist[this.i];
      this.apiService.router.navigate(['/artwork/', prevArtwork.id]);
    }
  }

}
