import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {

  artist: any;
  topartist: any;

  constructor(
    public apiService: apiservice,
    public constant: ConstantsService,
    public spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getartist();
    this.gettopartist();
  }

  getartist() {
    this.apiService.getRequest(this.constant.ARTIST, '').then((res: any) => {
      this.artist = res['data'];
    }).catch(err => {
      console.log(err);
    });
  }

  gettopartist() {
    this.apiService.getRequest(this.constant.ARTIST + '?top=1', '').then((res: any) => {
      this.topartist = res['data'];
    }).catch(err => {
      console.log(err);
    });
  }

}
