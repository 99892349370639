import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';
import { CookiesService } from '../service/cookies.service';

@Component({
  selector: 'app-art1',
  templateUrl: './art1.component.html',
  styleUrls: ['./art1.component.scss']
})

export class Art1Component implements OnInit {

  selectedartist: any;
  artist: any;

  constructor(
    public apiService: apiservice,
    public constant: ConstantsService,
    public spinner: NgxSpinnerService,
    public cookies: CookiesService) { }

  ngOnInit(): void {
    this.selectedartist = this.apiService.router.url.split('/')[2];
    this.getgallery();
  }

  getgallery() {
    this.apiService.getRequest(this.constant.SINGLEARTIST + '?id=' + this.selectedartist, '').then((res: any) => {
      this.artist = res['data'][0];
      this.cookies.update('artistArtWorkList', this.artist['artworks']);
      // this.storage.updateItem('artistArtWorkList', this.artist['artworks']);
    }).catch(err => {
      console.log(err);
    });
  }

}

