import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ArtistsComponent } from './artists/artists.component';
import { ArtworkComponent } from './artwork/artwork.component';
import { ContactComponent } from './contact/contact.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ShowsComponent } from './shows/shows.component';
import { WorkshopsComponent } from './workshops/workshops.component';
import { Art1Component } from './art1/art1.component';
import { NewShowComponent } from './new-show/new-show.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home/:id', component: HomeComponent },
  { path: 'aboutus', component: AboutUsComponent },
  { path: 'artists', component: ArtistsComponent },
  { path: 'artwork/:id', component: ArtworkComponent },
  { path: 'contactus', component: ContactComponent },
  { path: 'events', component: GalleryComponent },
  { path: 'artists/:id', component: Art1Component },
  { path: 'shows', component: ShowsComponent },
  { path: 'workshops', component: WorkshopsComponent },
  { path: 'the-pink-dot', component: NewShowComponent },
  { path: 'blogs', loadChildren: () => import('./blogs/blogs.module').then(b1 => b1.BlogsModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
