import { Component, OnInit } from '@angular/core';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-new-show',
  templateUrl: './new-show.component.html',
  styleUrls: ['./new-show.component.scss']
})
export class NewShowComponent implements OnInit {

  public totalArtworksList: Array<any> = []; // Store all data from API here
  public displayArtworksList: Array<any> = []; // Visible chunk of artworks to display
  public limit: number = 20;  // Number of items to display at once
  public currentPage: number = 1;

  constructor(
    public apiService: apiservice,
    public constant: ConstantsService) { }

  ngOnInit(): void {
    this.getAllArtworks();
  }

  async getAllArtworks() {
    try {
      // Fetch all data from the API
      const res: any = await this.apiService.getRequest(this.constant.GALLERY + `?all_artwork=true&section=pink-dot`, '');
      this.totalArtworksList = res.data || [];
      // this.loadMoreArtworks();  // Initially load first chunk
      this.apiService.storage.updateItem('artworkList', this.totalArtworksList);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  loadMoreArtworks() {
    const start = (this.currentPage - 1) * this.limit;
    const end = this.currentPage * this.limit;
    const nextChunk = this.totalArtworksList.slice(start, end);

    if (nextChunk.length > 0) {
      this.displayArtworksList = [...this.displayArtworksList, ...nextChunk];
      this.currentPage++;
      console.log(this.displayArtworksList, this.currentPage);
    }
  }

  onScroll(): void {
    this.loadMoreArtworks();  // Load the next chunk of data from the array
  }

}
