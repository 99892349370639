<section class="team1 cid-suylmxCp71" id="team1-j" style="margin-top: 30px; margin-bottom: 20px;">
    <div class="container">
        <div class="row gy-4 align-items-stretch justify-content-center">
            <div class="col-12 pt">
                <!-- <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>Shows</strong>
                </h3> -->

                <h3 class="align-center display-4 fontcolor_red" data-heading="Shows"><span data-heading="Shows">Shows</span></h3>
            </div>
            <div class="container pt">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5" *ngIf="showsList">
                    <div class="col" *ngFor="let i of showsList.slice(0, 5)">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <!-- <img src="assets/S1/1b5d8c64-09a9-4d90-a63e-7887252382b7.JPG"> -->
                                <img [src]="apiService?.fileUrl + i['data'][0].eventPhoto" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/6d214566-133d-41e9-b22b-5fb0035b9536.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/8e17e2d4-139f-44c8-ab26-1b5910a90a5c.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/35fb2751-b0e1-4239-8f9b-1de590d06907_Original.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/49a354e3-148e-481b-970a-173e6350c1e6.JPG">
                            </div>
                        </div>
                    </div> -->
                </div>
                <br>
                <!-- 
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/684f7b52-05a0-428d-b265-a2b7edc78665.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/38763c2a-4aa6-4744-934e-fac61bedfedd.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/ba6b99b9-f7cc-4b27-8c95-a61c54d9f3df.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/IMG_0161_Original.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/IMG_4050.JPG">
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/IMG_2098.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/IMG_2262.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/IMG_3822.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/IMG_4048.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/S1/IMG_2097.JPG">
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="content-wrap">
                <h6 class="mbr-role mbr-fonts-style align-center mb-3 display-4 ">
                    <button type="button" class="btn" routerLink="/shows">See More</button>
                </h6>
            </div>
        </div>
    </div>
</section>
<section class="team1 cid-suylmxCp71" id="team1-j">
    <div class="container pt">
        <div class="row gy-4 align-items-stretch justify-content-center">
            <div class="col-12">
                <!-- <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong>Workshops</strong>
                </h3> -->

                <h3 class="align-center display-4 fontcolor_red" data-heading="Workshops"><span data-heading="Workshops">Workshops</span></h3>
            </div>
            <div class="container pt">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5" *ngIf="workShopList">
                    <div class="col" *ngFor="let i of workShopList.slice(0, 5)">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img [src]="apiService?.fileUrl + i['data'][0].eventPhoto" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/18307e09-2941-4e17-99b9-13f187c2cde1.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/20200906_125346.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/23753177-d167-474e-b30f-10efcfa1b8be.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/cc2b69a6-f967-45cd-97a9-3f4d521f0de9.JPG">
                            </div>
                        </div>
                    </div> -->
                </div>
                <br>
                <!-- 
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/f709b26b-5a40-49ed-9eac-651c9460a15a.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_0095.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_0097.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_0107.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_0234.JPG">
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5">
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_0458.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_0914.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_2308.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_2309.JPG">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card-wrap">
                            <div class="image-wrap">
                                <img src="assets/W1/IMG_2313.JPG">
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="content-wrap">
                <h6 class="mbr-role mbr-fonts-style align-center mb-5 display-4">
                    <button type="button" class="btn" routerLink="/workshops">See More</button>
                </h6>
            </div>
        </div>
    </div>
</section>